<template>
<div>
  <header-nav></header-nav>
  <div class="banner">
    <img :src="require('@/assets/images/aboutus.jpg')" alt="" />
  </div>
  <div class="banner2">
    <div class="banner-con">
      <div class="top-tit">关于蓝目</div>
      <div class="uscon">
        <div class="ust">杭州蓝目数字信息技术有限公司</div>
        <div class="usw pad">
          一家具有丰厚底蕴的AI程序化交易软件开发的数字信息公司,坐落在杭州湾信息港小镇。公司面向一切对中国A股市场有狂热憧憬的机构及个人投资者，面对巨大的商机和挑战。
        </div>
        <div class="usw">
          我们是致力于研究AI智能交易的一个团队;我们潜心专研，希望通过人工智能解放用户的大脑，不仅高效，而且精准。
        </div>
      </div>
    </div>
  </div>
  <div class="product">
    <div class="name">产品优势</div>
    <div class="prolist">
      <div class="item">
        <div class="leimg">
          <img :src="require('@/assets/images/bianx.png')" alt="" />
        </div>
        <div class="rcon">
          <div class="conname">编写简单</div>
          <div class="conw">策略编写可以简单,可以普及。策略编写不再是计算机高端人才的自留地。无需掌握计算机编程语言,也能轻松完成策略编写。</div>
        </div>
      </div>
      <div class="item">
        <div class="leimg">
          <img :src="require('@/assets/images/xiad.png')" alt="" />
        </div>
        <div class="rcon">
          <div class="conname">批量下单</div>
          <div class="conw">
            进行批量操作，能够快速的进行订单的操作,从而节省大量的重复操作时间。
          </div>
        </div>
      </div>
      <div class="item">
        <div class="leimg">
          <img :src="require('@/assets/images/loud1.png')" alt="" />
        </div>
        <div class="rcon">
          <div class="conname">标的筛选</div>
          <div class="conw">
            真实还原历史行情，模拟真实交易，回测数据有迹可循。计算机选股操作代替人工手动操作，高效快捷。

          </div>
        </div>
      </div>
      <div class="item">
        <div class="leimg">
          <img :src="require('@/assets/images/ma1.png')" alt="" />
        </div>
        <div class="rcon">
          <div class="conname">多账户交易</div>
          <div class="conw">
           可多个账号共同管理、同时操作,反应更迅速，界面更简洁。
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chuangx">
    <div class="chuangcon">
      <div class="tname">创新技术</div>
      <div class="itlist">
        <div class="itcon">
          <div class="con-img"><img :src="require('@/assets/images/learn1.png')" alt="" /></div>
          <div class="con-w">AI自学习</div>
        </div>
        <div class="itcon">
          <div class="con-img"><img :src="require('@/assets/images/langu1.png')" alt="" /></div>
          <div class="con-w">语言先进领跑当下</div>
        </div>
        <div class="itcon">
          <div class="con-img"><img :src="require('@/assets/images/jier1.png')" alt="" /></div>
          <div class="con-w">各交易端皆可接入</div>
        </div>
        <div class="itcon">
          <div class="con-img"><img :src="require('@/assets/images/gengx.png')" alt="" /></div>
          <div class="con-w">后台数据完整准确实时更新</div>
        </div>
      </div>
    </div>
  </div>
  <div class="ywcon">
    <div class="topname">
      业务介绍
    </div>
    <div class="conlist">
      <div class="itcon">
        <div class="itimg">
          <img :src="require('@/assets/images/aboutus31.jpg')" alt="" />
        </div>
        <div class="wcon">
          <div class="wtit">自动化策略</div>
          <div class="lws">
            <p> 1.价格趋势跟踪;
            </p><p> 2.价格波动率跟踪;
            </p><p> 3.财务分析及投研跟踪;
            </p><p> 4.机器自动调整参数策略;
            </p><p> 5.机器自学习(基于贝叶斯概率公式)策略。
            </p>
          </div>
        </div>
      </div>
      <div class="itcon">
        <div class="itimg">
          <img :src="require('@/assets/images/aboutus32.jpg')" alt="" />
        </div>
        <div class="wcon">
          <div class="wtit">系统特征</div>
          <div class="lws" style="line-height:23px">
            <p>进行批量操作,能够快速的</p>
             <p>进行订单的操作，从而节省大量的</p>
             <p>重复操作时间。</p>
          </div>
        </div>
      </div>
      <div class="itcon">
        <div class="itimg">
          <img :src="require('@/assets/images/aboutus33.jpg')" alt="" />
        </div>
        <div class="wcon">
          <div class="wtit">策略实践</div>
          <div class="lws" style="line-height:23px">
          <p>可多个账号共同管理，同时</p> 
          <p>操作，反应更迅速，界面更简洁。</p>  
          </div>
        </div>
      </div>
    </div>
  </div>
  <floor></floor>
  </div>
</template>

<script>
import HeaderNav from "@/components/Header.vue";
import Floor from "@/components/Floor.vue";

export default {
  components: {
    HeaderNav,
    Floor,
  },
}

</script>

<style  scoped lang="less">
.banner {
  width: 100%;
  min-width: 1000px;
  margin: 0;
  padding: 0;
  img {
    width: 100%;
  }
}
.banner2 {
  width: 100%;
  min-width: 1100px;
  height: 621px;
  background-image: url("../../assets/images/aboutus2.jpg");
  background-repeat: no-repeat;
  background-position: center bottom;
  .banner-con {
    width: 1100px;
    margin: 0 auto;
    .top-tit {
      width: 100%;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
    }
    .uscon {
      width: 570px;
      text-align: left;
      padding: 30px;
      box-sizing: border-box;
      color: #ffffff;
      .ust {
        padding: 25px 0;
        font-size: 26px;
        font-weight: 600;
      }
      .usw {
        line-height: 35px;
      }
      .pad {
        padding-bottom: 50px;
      }
    }
  }
}
.product {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  .name {
    font-size: 32px;
    padding: 40px 0;
  }
  .prolist {
    width: 900px;
    margin: 0 auto;
    display: grid;
    padding-bottom: 110px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 20px;
    .item {
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .leimg {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        box-sizing: border-box;
      }
      .rcon {
        text-align: left;
        padding-left: 20px;
        .conname {
          font-size: 22px;
          color: #666666;
          padding: 20px 0;
        }
        .conw {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
.chuangx{
  width: 100%;
  min-width: 1100px;
  height: 490px;
  background-image: url("../../assets/images/abouts3.jpg");
  background-repeat: no-repeat;
  background-position: center bottom;
  .chuangcon{
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    .tname{
      font-size: 28px;
      color: #ffffff;
      padding: 40px 0;
    }
    .itlist{
      display: flex;
      justify-content: space-around;
      .itcon{
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .con-img{
          width: 90px;
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .con-w{
          align-items: center;
          padding-top: 50px;
          color: #ffffff;
          font-size: 18px;
          max-width: 160px;
        }
      }
    }
  }
}
.ywcon{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 250px;
  .topname{
    width: 100%;
    text-align: center;
    font-size: 32px;
    padding: 40px 0;
  }
  .conlist{
    display: flex;
    justify-content: space-between;
    .itcon{
      width: 361px;
      background: #f6f7fc;
      .itimg{
        width: 100%;
        img{
          width: 100%;
        }
      }
      .wcon{
        width: 100%;
        text-align: center;
        .wtit{
          font-size: 22px;
          padding: 22px 0;
        }
        .lws{
          font-size: 14px;
          line-height: 35px;
          color: rgba(0, 0, 0, 0.3);
          padding-bottom: 20px;
        }
      }
    }
  }
}
</style>